<template>
  <div class="top-bar-container">
    <!--
    <div class="notifications top-bar-item">
      <span class="icon">
        <font-awesome-icon :icon="['fas', 'bell']" />
      </span>
    </div>
    
    <router-link
      to="/portal/settings"
      class="settings top-bar-item"
      :class="[{ active: /\/portal\/settings*/.test(currentPage) }]"
    >
      <span class="icon">
        <font-awesome-icon :icon="['fas', 'cog']" />
      </span>
    </router-link>
    -->
    <div class="profile top-bar-item" v-on:click="toggleProfileDropdown">
      {{ user.firstName }}
      <span class="icon">
        <font-awesome-icon
          v-if="profileDropdownOpen"
          :icon="['fas', 'chevron-up']"
        />
        <font-awesome-icon v-else :icon="['fas', 'chevron-down']" />
      </span>
    </div>
    <div
      v-if="user !== null && profileDropdownOpen"
      class="profile-dropdown-options"
    >
      <div class="top">
        Ingelogd als
        <p class="user">{{ user.firstName }} {{ user.lastName }}</p>
      </div>
      <div class="actions">
        <div v-on:click="logout" class="action">Uitloggen</div>
      </div>
    </div>
  </div>
</template>

<script>
import { mapGetters, mapActions } from "vuex";
export default {
  name: "ThePortalTopBar",
  data() {
    return {
      profileDropdownOpen: false,
    };
  },
  computed: {
    ...mapGetters({
      user: "user/user",
    }),
    currentPage() {
      return this.$route.path;
    },
  },
  methods: {
    ...mapActions({
      logout: "auth/logout",
    }),
    toggleProfileDropdown() {
      this.profileDropdownOpen = !this.profileDropdownOpen;
    },
  },
};
</script>

<style lang="scss" scoped>
@import "@/assets/styles/_variables.scss";

.top-bar-container {
  border-bottom: 1px solid $lm-divider-color;
  height: 100%;
  display: flex;
  justify-content: flex-end;
  align-items: center;

  .top-bar-item {
    font-size: 1.2rem;
    margin-left: 35px;
    cursor: pointer;
    text-decoration: none;
    color: $lm-text-color;
    transition: 0.2s color;

    .icon {
      font-size: 1.5rem;
    }
  }

  .top-bar-item:hover {
    color: darken($lm-text-color, 30%);
  }

  .profile {
    .icon {
      font-size: 1rem;
    }
  }

  .active {
    color: $lm-brand-color;
  }

  .profile-dropdown-options {
    margin: 0;
    width: 150px;
    height: auto;
    position: fixed;
    right: calc(#{$standard-padding} * 2);
    top: calc(#{$nav-bar-height}/ 2 + #{$standard-padding});
    background-color: darken($lm-background-color, 10%);
    border-radius: $standard-radius;
    padding-left: $standard-padding;
    padding-right: $standard-padding;
    .top {
      padding-top: $standard-padding;
      padding-bottom: $standard-padding;
      border-bottom: 1px solid $lm-divider-color;
      .user {
        font-weight: 600;
      }
    }
    .actions {
      padding-bottom: $standard-padding;
      .action {
        margin-top: calc(#{$standard-margin} / 2);
        padding: calc(#{$standard-padding} / 3);
        border-radius: $standard-radius;
        transition: color 0.2s;
        transition: background-color 0.2s;
        cursor: pointer;
      }
      .action:hover {
        background-color: darken($lm-background-color, 15%);
        color: lighten($lm-text-color, 20%);
      }
    }
  }
}
</style>
