<template>
  <div class="side-bar-container">
    <div class="brand-container">
      <div class="brand-logo">
        <BaseBrandIcon />
      </div>
      <div class="brand-name">The Menu</div>
    </div>
    <div class="side-bar-navigation">
      <!-- <div
        v-if="portalProducts.website.subscribed === true"
        class="side-bar-navigation-item"
        :class="[{ active: /\/portal\/business*/.test(currentPage) }]"
      >
        <div class="item-color-strip"></div>
        <div class="item-label">
          <router-link to="/portal/business/main">
            <span class="icon">
              <font-awesome-icon :icon="['fas', 'hotel']" />
            </span>
            <span class="label">Bedrijfsinfo</span>
          </router-link>
        </div>
      </div> -->
      <div
        v-if="portalProducts.onlineMenubook.subscribed === true"
        class="side-bar-navigation-item"
        :class="[{ active: /\/portal\/menubookEditor*/.test(currentPage) }]"
      >
        <div class="item-color-strip"></div>
        <div class="item-label">
          <router-link to="/portal/menubookEditor/content">
            <span class="icon">
              <font-awesome-icon :icon="['fas', 'book-open']" />
            </span>
            <span class="label">Menuboek</span>
          </router-link>
        </div>
        <div
          v-if="/\/portal\/menubookEditor*/.test(currentPage)"
          class="sub-navitagion-items"
        >
          <router-link
            v-if="portalProducts.onlineMenubook.tier >= 1"
            to="/portal/menubookEditor/content"
            :class="[
              {
                active: /\/portal\/menubookEditor\/content*/.test(currentPage),
              },
            ]"
            >Menuboek inhoud</router-link
          >
          <router-link
            v-if="portalProducts.onlineMenubook.tier >= 2"
            to="/portal/menubookEditor/personalisation"
            :class="[
              {
                active: /\/portal\/menubookEditor\/personalisation*/.test(
                  currentPage
                ),
              },
            ]"
            >Personalisatie</router-link
          >
        </div>
      </div>
      <div
        v-if="portalProducts.website.subscribed === true"
        class="side-bar-navigation-item"
        :class="[{ active: /\/portal\/websiteEditor*/.test(currentPage) }]"
      >
        <div class="item-color-strip"></div>
        <div class="item-label">
          <router-link to="/portal/websiteEditor/main">
            <span class="icon">
              <font-awesome-icon :icon="['fas', 'globe']" />
            </span>
            <span class="label">Website</span>
          </router-link>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import BaseBrandIcon from "@/components/BaseBrandIcon.vue";
import { mapGetters } from "vuex";
export default {
  name: "ThePortalSideBar",
  components: {
    BaseBrandIcon,
  },
  computed: {
    ...mapGetters({
      portalProducts: "business/selectedBusinessPortalProducts",
    }),
    currentPage() {
      return this.$route.path;
    },
  },
};
</script>

<style lang="scss" scoped>
@import "@/assets/styles/_variables.scss";

.side-bar-container {
  height: 100%;
  border-right: 1px solid $lm-divider-color;

  .brand-container {
    height: 75px;
    display: flex;
    justify-content: center;
    align-items: center;
    .brand-logo {
      background-color: $lm-brand-color;
      border-radius: $standard-radius;
      width: 55px;
      height: 55px;
      box-sizing: border-box;
      padding: 5px;
    }

    .brand-name {
      padding-left: 10px;
      font-family: $brand-font-family;
      font-size: 2rem;
      font-weight: 600;
      width: auto;
    }
  }

  .side-bar-navigation {
    margin-top: 15vh;

    .side-bar-navigation-item {
      padding-top: $standard-padding;
      padding-bottom: $standard-padding;

      .item-color-strip {
        border-top-right-radius: 5px;
        border-bottom-right-radius: 5px;
        width: 5px;
        float: left;
        height: 50px;
      }
      .item-label {
        height: 50px;
        display: flex;
        align-items: center;
        a {
          padding-left: calc(#{$standard-padding}* 2);
          cursor: pointer;
          display: flex;
          align-items: center;
          text-decoration: none;
          color: $lm-text-color;
          transition: 0.1s color;
          .icon {
            font-size: 1.8rem;
            padding-right: 10px;
          }

          .label {
            font-size: 1.2rem;
          }
        }

        a:hover {
          color: darken($lm-text-color, 30%);
        }
      }
      .sub-navitagion-items {
        width: 100%;
        margin-left: calc(#{$standard-margin} * 5);
        a {
          text-decoration: none;
          color: $lm-text-color;
          display: block;
          margin-top: calc(#{$standard-margin} / 2);
          transition: 0.1s color;
        }

        .active {
          font-weight: 500;
        }

        a:hover {
          color: darken($lm-text-color, 30%);
        }
      }
    }

    .side-bar-navigation-item.active {
      .item-color-strip {
        background-color: $lm-brand-color;
      }
      .item-label {
        a {
          .label {
            font-weight: 500;
          }
        }
      }
    }
  }
}
</style>
