<template>
  <div id="container-app">
    <div id="loading" v-if="isFetchingData === true">
      <div class="loading-icon">
        <div class="brand-logo">
          <BaseBrandIcon />
        </div>
      </div>
      <div class="loading-text">
        Even geduld terwijl we de essentiële data voor de werking van het
        portaal ophalen.
      </div>
    </div>
    <div v-else id="layout">
      <div id="side-bar">
        <ThePortalSideBar />
      </div>
      <div id="top-bar">
        <ThePortalTopBar />
      </div>
      <div id="body">
        <div id="notifications">
          <BaseNotification
            v-for="notification in notifications"
            :key="notification.id"
            :notification="notification"
          />
        </div>
        <router-view></router-view>
      </div>
    </div>
  </div>
  <div id="mobile-error">
    <a href="https://www.themenu.be"><BaseBrandIcon /></a>
    <p>
      Het The Menu portaal is ontworpen voor tablets en desktop apparaten. Voor
      een goede ervaring open je best deze pagina op je computer of tablet.
    </p>
  </div>
</template>

<script>
import BaseNotification from "../components/BaseNotification.vue";
import ThePortalSideBar from "../components/ThePortalSideBar.vue";
import ThePortalTopBar from "../components/ThePortalTopBar.vue";
import BaseBrandIcon from "@/components/BaseBrandIcon.vue";
import { mapActions, mapGetters } from "vuex";
export default {
  name: "PortalLayout",
  components: {
    ThePortalSideBar,
    ThePortalTopBar,
    BaseNotification,
    BaseBrandIcon,
  },
  data() {
    return {
      isFetchingData: true,
    };
  },
  async created() {
    await this.fetchUser();
    await this.fetchBusinesses();
    this.isFetchingData = false;
  },
  methods: {
    ...mapActions({
      fetchUser: "user/fetchUser",
      fetchBusinesses: "business/fetchBusinesses",
    }),
  },
  computed: {
    ...mapGetters({
      notifications: "notification/notifications",
    }),
  },
};
</script>

<style lang="scss" scoped>
@import "@/assets/styles/_variables.scss";

#loading {
  height:100vh;
  display: flex;
  justify-content: center;
  align-items: center;
  flex-direction: column;
  .loading-icon{
    margin-bottom: $standard-margin;
      .brand-logo {
      background-color: $lm-brand-color;
      border-radius: $standard-radius;
      width: 55px;
      height: 55px;
      box-sizing: border-box;
      padding: 5px;
    }
  }
}

#notifications {
  position: absolute;
  width: 100%;
  max-width: 450px;
  margin: auto;
  top: calc(#{$standard-padding} * 1);
  margin: auto;
  left: 0;
  right: 0;
}

#layout {
  display: grid;
  grid-template-columns: 300px auto;
  grid-template-rows: 75px auto;
  height: 100vh;
  overflow: hidden;

  #side-bar {
    grid-column: 1 / span 1;
    grid-row: 1 / span 2;
    position: relative;
    z-index: 2;
  }

  #top-bar {
    grid-column: 2 / span 1;
    grid-row: 1 / span 1;
    padding-left: calc(#{$standard-padding} * 3);
    padding-right: calc(#{$standard-padding} * 3);
    position: relative;
    z-index: 2;
  }

  #body {
    grid-column: 2 / span 1;
    grid-row: 2 / span 1;
    padding-left: calc(#{$standard-padding} * 3);
    padding-right: calc(#{$standard-padding} * 3);
    height: 100%;
    overflow-y: hidden;
    position: relative;
    z-index: 1;
  }
}

#mobile-error {
  display: none;
}

@media only screen and (max-width: 768px) {
  #container-app {
    display: none;
  }
  #mobile-error {
    height: 100vh;
    display: flex;
    justify-content: center;
    align-items: center;
    flex-direction: column;
    margin-left: $standard-margin;
    margin-right: $standard-margin;
    a {
      width: 60px;
      height: 60px;
      background-color: $lm-brand-color;
      margin-bottom: $standard-margin;
      padding: 6px;
      box-sizing: border-box;
      border-radius: $standard-radius;
    }
    p {
      text-align: center;
    }
  }
}
</style>
